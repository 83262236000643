export default ({
    namespaced: true,
    state: {
        after_sent_success: false,
    },

    getters: {
        checkQuestionSent(state) {
            return state.after_sent_success
        },
    },

    mutations: {
        SET_AFTER_SENT_SUCCESS (state) {
            state.after_sent_success = true
        },
    },
    actions: {
        async setAfterSentSuccess({commit}){
            commit('SET_AFTER_SENT_SUCCESS')
        },    
    }
})