import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import router from './router'
import store from './store'
import axios from 'axios'
import App from './App.vue'
import Toaster from '@meforma/vue-toaster';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

//Import Fontawesome 
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

//Init subscriber 
require ('@/store/subscriber')

axios.defaults.baseURL = 'https://laravelbackenddemo.imexdental.de/api'
// axios.defaults.baseURL = 'https://laravelbackend.imexdental.de/api'
// axios.defaults.baseURL = 'http://127.0.0.1:8888/apilaravel/public/api'

//re-authenticate user calling attempt function from store/auth.js
store.dispatch('auth/attempt', localStorage.getItem('access_token_saved')).then(() => {
    //createApp inside to avoid flickering
    createApp(App)
        .use(store)
        .use(router)
        .use(Toaster)
        .use(VueSweetalert2)
        .component('font-awesome-icon', FontAwesomeIcon)
        .mount('#app')
})

import '@/assets/css/main.css'
import "bootstrap/dist/js/bootstrap.js"

// Timer to refresh window after 5 minutes
let time = new Date().getTime();
const setActivityTime = () => {
  time = new Date().getTime();
}
document.body.addEventListener("mousemove", setActivityTime);
document.body.addEventListener("keypress", setActivityTime);
const refresh = () => {
  if (new Date().getTime() - time >= 300000) {
    window.location.reload(true);
  } else {
    setTimeout(refresh, 10000);
  }
}
setTimeout(refresh, 10000);