import axios from "axios";

export default ({
    namespaced: true,
    state: {
        access_token_saved: null,
        user: null,
        participation: null,
    },

    getters: {
        authenticated(state) {
            return state.access_token_saved && state.user
        },
        user (state) {
            return state.user
        },
        participation (state) {
            return state.participation
        }
    },

    mutations: {
        destroyToken(state){
            state.access_token_saved = null
        },

        SET_TOKEN (state, access_token) {
            state.access_token_saved = access_token
        },

        SET_USER (state, data){
          state.user = data  
        },
        SET_QUESTION (state, participation) {
            state.participation = participation
        },
    },
    actions: {
        async signIn({dispatch}, credentials) {
            let response = await axios.post('auth/signin', credentials)
            return dispatch('attempt', response.data.access_token)
        },

        //Action attempt authentication and commit the token and the user
        async attempt({commit, state}, access_token){
            if(access_token){
                commit('SET_TOKEN', access_token)
            }

            if(!state.access_token_saved){
                return
            }

            try {
                let response = await axios.get('auth/me')
                commit('SET_USER', response.data)

                let response2 = await axios.get('get-participation')
                commit('SET_QUESTION', response2.data.success)
            } catch (e) {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                commit('SET_QUESTION', null)
            }
        },

        removeTokenUser(context){
            context.commit('destroyToken')
            localStorage.removeItem('access_token_saved')
        },
        
        async signOut(context) {
            if (context.getters.authenticated) {
                return new Promise((resolve, reject) => {
                    axios.post('auth/logout')
                    .then(response => {
                        localStorage.removeItem('access_token_saved')
                        context.commit('destroyToken')
                        resolve(response)
                    })
                    .catch(error => {
                        localStorage.removeItem('access_token_saved')
                        context.commit('destroyToken')
                        reject(error)
                    })
                })
            }
        }
    }
})
