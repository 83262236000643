<template>
    <div class="container questions-container">
    <br>
        <h1>Umfragebogen</h1>
        <br>
        <h2>
            Zu bewerten in Schulnoten von 1 (sehr gut) bis 6 (ungenügend).
        </h2>
        <br>
        <form action="#" @submit.prevent="saveAnswers">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div>
                                <div class="row" style="color: #212529; font-weight: 500;">
                                    <div class="col">
                                        TECHNIK
                                    </div>
                                    <div class="col">
                                        Passgenauigkeit
                                    </div>
                                    <div class="col">
                                        Okklusion
                                    </div>
                                    <div class="col">
                                        Farbe
                                    </div>
                                </div><br>
                                <div class="row">
                                    <div class="col">
                                        Kronen/Brücken
                                    </div>
                                    <div class="col">
                                        <select v-model="kronen_p">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kronen_o">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kronen_f">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>    
                                </div>
                                <div style="height:10px;"></div>
                                <div class="row">
                                    <div class="col">
                                        Kombi
                                    </div>
                                    <div class="col">
                                        <select v-model="kombi_p">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kombi_o">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kombi_f">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>    
                                </div>
                                <div style="height:10px;"></div>
                                <div class="row">
                                    <div class="col">
                                        Kunststoff/Totale
                                    </div>
                                    <div class="col">
                                        <select v-model="kunststoff_p">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kunststoff_o">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kunststoff_f">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>    
                                </div><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    ÄSTHETIK
                                </div>
                                <div class="col">
                                    Oberflächentextur
                                </div>
                                <div class="col">
                                    Korrekte Anatomie
                                </div>
                                <div class="col">
                                    Harmonisches Gesamtbild
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    
                                </div>
                                <div class="col">
                                    <select v-model="aesthtik_o">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="aesthtik_k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="aesthtik_h">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    TECHNISCHER SERVICE
                                </div>
                                <div class="col">
                                    Erreichbarkeit der Technikhotline
                                </div>
                                <div class="col">
                                    Kompetenz der Mitarbeiter/innen
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Telefonische Hilfe
                                </div>
                                <div class="col">
                                    <select v-model="telefonische_e">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="telefonische_k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="telefonische_f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div><br>
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                </div>
                                <div class="col">
                                    Schnelle Erledigung bei Problemen
                                </div>
                                <div class="col">
                                    Ausführung der Änderungen/Reparaturen
                                </div>
                                <div class="col">
                                    Leistungen der Service-Labore vor Ort
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Kulanzabwicklung
                                </div>
                                <div class="col">
                                    <select v-model="kulanzabwicklung_s">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kulanzabwicklung_a">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kulanzabwicklung_l">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div><br>
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                </div>
                                <div class="col">
                                    Kompetenz der Mitarbeiter/innen
                                </div>
                                <div class="col">
                                    Korrekte Erstellung der Rechnungen
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Rechnungserstellung
                                </div>
                                <div class="col">
                                    <select v-model="rechnungserstellung_s">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="rechnungserstellung_k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="rechnungserstellung_f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    ALLGEMEINE HOTLINE
                                </div>
                                <div class="col">
                                    Schnelle Erledigung/Antwort
                                </div>
                                <div class="col">
                                    Kompetenz/Korrekter KVA
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Kostenvoranschläge
                                </div>
                                <div class="col">
                                    <select v-model="kosten_s">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kosten_k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kosten_f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div><br>
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                                <div class="col">
                                    Termingerechte Abholung/Zustellung
                                </div>
                                <div class="col">
                                    Zufriedenheit mit der Hilfestellung
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Versand
                                </div>
                                <div class="col">
                                    <select v-model="versand_f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="versand_t">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="versand_z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-6" style="margin-bottom: 10px;" v-for="question in questionsdynamic" :key="question.id">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    {{question.questions}}
                                </div>
                            </div><br>
                            <div class="row" v-if="question.typ == 1">
                                <div class="col">
                                    <select v-model="question.id">
                                        <option disabled>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>  
                            </div>
                            <div v-else-if="question.typ == 2">
                                <div class="row">
                                    <div class="col">
                                        <select v-model="question.id">
                                            <option disabled>Auswählen</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                    </div>
                                </div><br>
                                <div class="row">
                                    <div class="col">
                                        <input maxlength="500" style="width: 100%; padding: 5px;" placeholder="Ihre Begründung falls NEIN" />
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="question.typ == 3">
                                <div class="row">
                                    <div class="col">
                                        <select v-model="question.id">
                                            <option disabled selected>Auswählen</option>
                                            <option value="JA">JA</option>
                                            <option value="NEIN">NEIN</option>
                                        </select>
                                    </div>
                                </div><br>
                                <div class="row">
                                    <div class="col">
                                        <input maxlength="500" style="width: 100%; padding: 5px;" placeholder="Ihre Begründung falls JA" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else-if="question.typ == 4">
                                <div class="col">
                                    <textarea maxlength="500" style="width: 100%; padding: 5px;" v-model="question.id"></textarea>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-success">SENDEN</button>

        </form>
        <br>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Questions',
    data: () => ({
        block_id: '',
        questionsdynamic: [],
        kronen_p: '',
        kronen_o: '',
        kronen_f: '',
        kombi_p: '',
        kombi_o: '',
        kombi_f: '',
        kunststoff_p: '',
        kunststoff_o: '',
        kunststoff_f: '',
        aesthtik_o: '',
        aesthtik_k: '',
        aesthtik_h: '',
        telefonische_e: '',
        telefonische_k: '',
        telefonische_f: '',
        kulanzabwicklung_s: '',
        kulanzabwicklung_a: '',
        kulanzabwicklung_l: '',
        rechnungserstellung_s: '',
        rechnungserstellung_k: '',
        rechnungserstellung_f: '',
        kosten_s: '',
        kosten_k: '',
        kosten_f: '',
        versand_f: '',
        versand_t: '',
        versand_z: '',
        dynamicquestions: [],
    }),
    computed: {
        ...mapGetters({
            userDataVue: 'auth/user',
            getAlreadyVoted: 'umfrage/checkQuestionSent'
        })
    },
    methods: {
        ...mapActions({
            setConfirmed: 'umfrage/setAfterSentSuccess',
        }),
        preapareDynamicQuestionsToSave() {
            this.dynamicquestions = []
            for (let i=0; i<this.questionsdynamic.length; i++) {
                if(this.questionsdynamic[i].typ == 1){
                    this.dynamicquestions.push({
                        [i] : this.questionsdynamic[i].id                   
                    })
                } else if(this.questionsdynamic[i].typ == 2){
                    this.dynamicquestions.push({
                        [i] : this.questionsdynamic[i].id                  
                    })
                } else if(this.questionsdynamic[i].typ == 3){
                    this.dynamicquestions.push({
                        [i] : this.questionsdynamic[i].id                  
                    })
                } else if(this.questionsdynamic[i].typ == 4){
                    this.dynamicquestions.push({
                        [i] : this.questionsdynamic[i].id                  
                    })
                }
            }
        },
        async loadDataQuestions(){
          try {
              let response = await axios.get('send-data')
              this.block_id = response.data.block
        
          } catch (e) {
            console.log(e)
          }
        },
        checkFields() {
            if( this.kronen_p === "" || this.kronen_o === "" || this.kronen_f === "" ||
                this.kombi_p === "" || this.kombi_o === "" || this.kombi_f === "" ||
                this.kunststoff_p === "" || this.kunststoff_o === "" || this.kunststoff_f === "" ||
                this.aesthtik_o === "" || this.aesthtik_k === "" || this.aesthtik_h === "" ||
                this.telefonische_e === "" || this.telefonische_k === "" || this.telefonische_f === "" ||
                this.kulanzabwicklung_s === "" || this.kulanzabwicklung_a === "" || this.kulanzabwicklung_l === "" ||
                this.rechnungserstellung_s === "" || this.rechnungserstellung_k === "" || this.rechnungserstellung_f === "" ||
                this.kosten_s === "" || this.kosten_k === "" || this.kosten_f === "" ||
                this.versand_f === "" || this.versand_t === "" || this.versand_z === ""
            ){
                this.$swal({
                    icon: 'warning',
                    title: 'Info',
                    text: 'Alle Felder müssen ausgefüllt werden',
                })
                return
            }
            return true
        },
        alreadyVoted(){
            if(this.getAlreadyVoted) {
                this.$swal({
                    icon: 'warning',
                    title: 'Info',
                    text: 'Sie haben bereits an der Umfrage teilgenommen',
                })
                return
            }
            return true;
        },
        async loadQuestions() {
            try {
                let response = await axios
                .get('load-questions') 
                if(response.data.questions == null) {
                    this.questionsdynamic = null
                } else {
                    this.questionsdynamic = response.data.questions
                }
            } catch (e) { 
                console.log(e)
            }
        },
        async saveAnswers(){  
            if(this.questionsdynamic != null){
                this.preapareDynamicQuestionsToSave()
            }
            if(this.alreadyVoted() && this.checkFields()){
                try {
                    let response = await axios
                    .get('create-question', { 
                        params: {
                            user_id: this.userDataVue.id,
                            kronen_p: this.kronen_p,
                            kronen_o: this.kronen_o,
                            kronen_f: this.kronen_f,
                            kombi_p: this.kombi_p,
                            kombi_o: this.kombi_o,
                            kombi_f: this.kombi_f,
                            kunststoff_p: this.kunststoff_p,
                            kunststoff_o: this.kunststoff_o,
                            kunststoff_f: this.kunststoff_f,
                            aesthtik_o: this.aesthtik_o,
                            aesthtik_k: this.aesthtik_h,
                            aesthtik_h: this.aesthtik_k,
                            telefonische_e: this.telefonische_e,
                            telefonische_k: this.telefonische_k,
                            telefonische_f: this.telefonische_f,
                            kulanzabwicklung_s: this.kulanzabwicklung_s,
                            kulanzabwicklung_a: this.kulanzabwicklung_a,
                            kulanzabwicklung_l: this.kulanzabwicklung_l,
                            rechnungserstellung_s: this.rechnungserstellung_s,
                            rechnungserstellung_k: this.rechnungserstellung_k,
                            rechnungserstellung_f: this.rechnungserstellung_f,
                            kosten_s: this.kosten_s,
                            kosten_k: this.kosten_k,
                            kosten_f: this.kosten_f,
                            versand_f: this.versand_f,
                            versand_t: this.versand_t,
                            versand_z: this.versand_z,
                            block_id: this.block_id,
                            dynamicquestions: JSON.stringify(this.dynamicquestions)
                        }
                    })
                    .then(() => {
                        this.setConfirmed()
                        this.$router.replace({ name: 'questionnaire-confirmed' })
                    })
                    return response
                } catch (e) { 
                    console.log(e)
                }
            }
        }
    },
    mounted(){
      this.loadQuestions()
      this.loadDataQuestions()
    }
}
</script>
<style lang="scss">
    .col{
        select{
            border: 2px solid #e5e7eb;
        }
    }

    .col {
        input {
            border: 2px solid #e5e7eb;
        }
    }

    .col {
        textarea {
            border: 2px solid #e5e7eb;
        }
    }

    .questions-container{
        h1{
            color: #17243d;
            font-family: "NotoSans-Medium";
            font-size: 2.1rem;
            font-weight: 100;
        }

        h2{
            font-size: 1.5rem;
        }
    }
</style>